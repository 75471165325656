
import React from 'react';

import { CategoriesFilterPanel } from '../../components/blog/categories-filter-panel';
import { Hero } from '../../components/blog/hero';
import { PostSidebar } from '../../components/blog/post-sidebar';
import { Breadcrumbs, BreadcrumbsItem } from '../../components/breadcrumbs';
import { Container } from '../../components/container';
import SEO from '../../components/seo';
import img1 from './post-5.jpg';
import styles from './post-grid.module.css';

const KrabickovaDietaPraha = () => {
    return (
      <div className={styles.wrapper}>
        <SEO
          title={"Účinná krabičková dieta v Praze | NutritionPro"}
          description={"Zhubněte díky účinné krabičkové dietě, bez starostí a bez námahy. Krabičková dieta v Praze podle nutričního plánu zkušené dietoložky. Pojďte do toho s námi."}
        />
        <Container>
          <Breadcrumbs style={{ margin: "24px 0" }}>
            <BreadcrumbsItem link="/">Domu</BreadcrumbsItem>
            <BreadcrumbsItem link="/blog/posts">Blog</BreadcrumbsItem>
            <BreadcrumbsItem link="/blog/zdrave-udrzitelne-hubnuti">
            Účinná krabičková dieta v Praze
            </BreadcrumbsItem>
          </Breadcrumbs>
        </Container>
        <Container>
          <div className={styles.postGrid}>
            <div>
              <Hero
                title="Zhubněte s krabičkami. Jednoduché a dokonale efektivní hubnutí"
                date="16.05.22"
              />
              <div>
                <p className={styles.postText}>
                Průzkumy mezi obézními lidmi a lidmi s nadváhou stále častěji ukazují, že <b>největší překážkou při hubnutí a zdravém životním stylu je čas</b>. Na první pohled se to nezdá, ale zdravé stravování vyžaduje mnoho času na přípravu jídel. Na začátku je pravidelný nákup čerstvých surovin, poté pravidelné vaření hned několika chodů na den. Pro vytížené lidi je to dlouhodobě těžce udržitelné. <b>Existuje ale jednoduché řešení</b>.
                </p>
                <img
                  src={img1}
                  className={styles.postImg}
                  style={{ marginBottom: "40px" }}
                  alt="img1"
                />                
                <h5 className={styles.postTitle}>
                Krabičková dieta
                </h5>
                <p className={styles.postText}>
                Už jste slyšeli o takzvané krabičkové dietě? Zejména ve větších městech je to stále oblíbenější řešení, jak zhubnout. <b>Krabičková dieta je kouzelná v tom, že vám umožní efektivně a zdravě zhubnout, a navíc vám ušetří čas</b>, který byste strávili nákupem a vařením. Pokud zvolíte krabičkovou dietu, budete už mít vše připravené, čerstvé jídlo každý den, a to klidně až 5chodové.
                </p>
                <p className={styles.postText}>
                    <em>
                    Díky <a href='https://nutritionpro.cz/'>krabičkové dietě</a> odbouráte ten největší problém při dlouhodobém redukčním plánu. Čas na přípravu jídla a neustálé vymýšlení, co budete vařit.
                    </em>
                </p>
                <h5 className={styles.postTitle}>
                Krabičky a jídelníček na míru sestaví dietolog
                </h5>
                <p className={styles.postText}>
                Krabičkové diety nejsou jen o tom, že si zvolíte program, dostáváte denně krabičky s navařeným jídlem a tím snížíte kalorie, které za den přijmete. Například my z NutritionPro <b>stavíme krabičkovou dietu na datech</b>. Ze všeho nejdřív tak projdete konzultací a <b>kontrolou s naší zkušenou dietoložkou</b>. Až na základě výsledků vám sestavíme jídelníček, do kterého zahrneme nejnovější poznatky ze světa dietologie. 
                </p>
                <p className={styles.postText}>
                    <em>
                    Výhodou naší krabičkové diety je zároveň fakt, že jídla se neopakují. Máme pro vás přichystaných 300 nutričně vyvážených a chutných jídel, se kterými bude hubnutí hračka. Navíc menu pravidelně obměňujeme. 
                    </em>
                </p>
                <h5 className={styles.postTitle}>
                Krabičky vám dají přesně tolik kalorií, kolik je potřeba
                </h5>
                <p className={styles.postText}>
                Díky speciálně stanovenému redukčnímu plánu, o který se postará náš chytrý algoritmus a zkušená dietoložka, <b>budeme přesně vědět, kolik kalorií potřebujete, abyste zhubli</b>. Vytvoříme optimální redukční plán, který budete dodržovat a kila půjdou dolů. <b>Zhubnout s krabičkovou dietou je snadné a stále oblíbenější</b>. Obrovskou výhodou také je, že se vyhnete jojo efektu, protože <b>krabičky nabízí udržitelné hubnutí</b>.
                </p>
                <h5 className={styles.postTitle}>
                Krabičky vám v Praze dovezeme až ke dveřím.
                </h5>
                <p className={styles.postText}>
                Sami si zvolíte, kdy chcete krabičky v Praze doručit. Snažíme se vám maximálně vyjít vstříc. Navíc <b>naše doprava je zdarma</b>, takže oproti jiným krabičkovým dietám můžete ušetřit. <b>Držet krabičkovou dietu v Praze je opravdu snadné</b>. Jídlo si můžete také vyzvednout na odběrném místě v centru Prahy. 
                </p>
                <h5 className={styles.postTitle}>
                Program hubnutí s NutritionPro
                </h5>
                <p className={styles.postText}>
                Teď víte, že <b>hubnutí s krabičkovou dietou je komfortní</b>. Nemusíte se bát hladovění ani neustálého chystání nových a nových jídel. Díky krabičkové dietě se prakticky nemusíte vůbec o nic starat. A jak vlastně hubnutí s NutritionPro probíhá?  
                </p>
                <ul className={styles.postList}>
                  <li>Na začátku si <strong>zvolíte délku programu</strong>, a to od 2 týdnů až po 3 měsíce – delší program je výhodnější pro vaše zdraví, ale také pro vaši peněženku.</li>
                  <li>Zároveň si řeknete, <strong>kolik jídel denně potřebujete</strong>. Vybrat si můžete 2 jídla, 3 nebo 5 jídel. S 5chodovým menu máte jistotu, že nebudete hladovět a vždy budete mít vyvážené jídlo, kterým se zasytíte.</li>
                  <li>Poté vás zkontroluje naše dietoložka a <strong>vytvoří vám jídelníček na míru</strong>, který respektuje vaše cíle a rychlost, se kterou chcete zhubnout.</li>
                  <li>Užíváte si chutná a nutričně vyvážená jídla každý den a <strong>sledujete, jak jdou kila dolů.</strong></li>
                </ul>
              </div>
            </div>
            <PostSidebar />
          </div>
        </Container>
      </div>
    )
  }
  
  export default KrabickovaDietaPraha